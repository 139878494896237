import React from 'react';

const AddressesTab = ({ 
  handleDeleteAddress, 
  addressData, 
  setAddressData, 
  handleInputChange, 
  userData, 
  newAddress, 
  setNewAddress, 
  handleAddAddress,
  countries,
  loadingCountries,
}) => {
  


  return (
    <div className="space-y-6">
      <div className="bg-[#252525] p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-white mb-4">My Addresses</h3>
        <div className="space-y-4">
          {/* Add new address form */}
          <form className="space-y-4" onSubmit={(e) => { e.preventDefault(); handleAddAddress(); }}>
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 space-y-4 sm:space-y-0">
              {/* Address Input */}
              <input
                type="text"
                name="address"
                value={addressData.address}
                onChange={(e) => setAddressData({ ...addressData, address: e.target.value })}
                placeholder="Enter new address"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-4 block w-full sm:text-sm border-gray-300"
              />

              {/* City Input */}
              <input
                type="text"
                name="city"
                value={addressData.city}
                onChange={(e) => setAddressData({ ...addressData, city: e.target.value })}
                placeholder="Enter city"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-4 block w-full sm:text-sm border-gray-300"
              />
            </div>

            {/* State and City Fields */}
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 space-y-4 sm:space-y-0">
              {/* State Input */}
              <input
                type="text"
                name="state"
                value={addressData.state}
                onChange={(e) => setAddressData({ ...addressData, state: e.target.value })}
                placeholder="Enter state"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-4 block w-full sm:text-sm border-gray-300"
              />

              
              {/* Country Dropdown */}
              <select
                name="country"
                value={addressData.country || ''}
                onChange={(e) => setAddressData({ ...addressData, country: e.target.value })}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 p-4"
                disabled={loadingCountries}
              >
                <option value="" disabled>
                  {loadingCountries ? 'Loading countries...' : 'Select a country'}
                </option>
                {countries.map((country) => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                text-white bg-[#b7a78b] hover:bg-[#d0c3a6] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Address
            </button>
          </form>


          {/* Address list */}
          <div className="space-y-4">
            {userData.addresses && userData.addresses.length > 0 ? (
              userData.addresses.map((address, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <p className="text-white">{`${address.address}, ${address.city}, ${address.state}, ${address.country}`}</p>
                  <button
                    onClick={() => handleDeleteAddress(address)}
                    className="px-2 py-1 bg-red-500 text-white text-sm rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No addresses added yet.</p>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default AddressesTab;
