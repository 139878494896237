import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setAddItemToCart, 
  setOpenCart, 
  selectTotalQTY,
  selectCurrency,
  setCurrency,
} from '../CartSlice';
import { useAuth } from "../context/AuthContext";
import shoppingBag from '../assets/shopping-bag.png';
import user from '../assets/user.png';
import Preloader from '../components/Pre';


const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [price, setPrice] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const dispatch = useDispatch();
  const totalQTY = useSelector(selectTotalQTY);
  const currency = useSelector(selectCurrency);
  const [selectedProductDetails, setSelectedProductDetails] = useState({});
  const [rates, setRates] = useState({});
  const [cart, setCart] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { token, logout } = useAuth(); // Get token and logout function
  const isLoggedIn = !!token;
  const dropdownRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisibleSize, setPopupVisibleSize] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  const handleCurrencyChange = (newCurrency) => {
    dispatch(setCurrency(newCurrency));
  };

  const handleQuantityChange = (change) => {
    setQuantity(prev => Math.max(0, prev + change));
  };

  const handleSizeChange = (e) => {
    const selected = product.sizes.find(size => size.size === e.target.value);
    setSelectedSize(selected.size);
    setPrice(selected.price);
    setSelectedProductDetails({ ...selectedProductDetails, [product.id]: { price: selected.price } });
  };

  


  const fetchExchangeRates = async () => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      const data = await response.json();
      setRates(data.rates);  // Store exchange rates
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  };

  useEffect(() => {
    fetchExchangeRates();
  }, []);


  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // const response = await fetch(`https://sope-backend.vercel.app/product/${id}`);
        const response = await fetch(`http://localhost:4000/product/${id}`);
        const data = await response.json();
        setProduct(data);
        setSelectedSize(data.sizes[0]?.size);  // Set initial size
        setPrice(data.sizes[0]?.price); 
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };
    fetchProductDetails();
  }, [id]);

  
  const onAddToCart = (item) => {
    // const quantity = getCartQuantity(item.id); // Default to 1 if no quantity exists
    const convertedPrice = convertPrice(price, 'USD', currency, rates);

    // Check if a size is selected
    if (!selectedSize) {
      setPopupVisibleSize(true); // Show size selection popup
      return;
    }

    // Check if quantity is at least 1
    if (quantity < 1) {
      setPopupVisible(true); // Show popup if quantity is 0
      return;
    }

    if (product) {
      const cartItem = {
        id: product.id,
        title: product.name,
        text: product.summary,
        img: product.images[0],
        price: price, // Store the numeric value
        cartQuantity: quantity,
        selectedSize: selectedSize,
        currency: currency, // Include selected currency
      };

      dispatch(setAddItemToCart(cartItem));

      // Open the cart after adding the product
      dispatch(setOpenCart({ cartState: true }));
    }
  };




  const updateCart = (product, change) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item.id === product.id);

      if (existingItem) {
        const updatedCart = prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: Math.max(0, item.quantity + change) }
            : item
        ).filter(item => item.quantity > 0);
        return updatedCart;
      } else if (change > 0) {
        return [...prevCart, { ...product, quantity: change }];
      }

      return prevCart;
    });
  };

  const getCartQuantity = (productId) => {
    const item = cart.find(item => item.id === productId);
    return item ? item.quantity : 0;
  };

  if (!product) {
    return <Preloader />;
  }

  // const naira = '\u20A6';


  const onCartToggle = () => {
    dispatch(setOpenCart({ cartState: true }));
  };



  // Define conversion rates
  const conversionRates = {
    NGN: 1,
    USD: 1677.9,
    EUR: 1782.5,
    GBP: 2132.2
  };

  // Helper function to convert prices between currencies
  const convertPrice = (amount, fromCurrency, toCurrency, rates) => {
    if (!rates || fromCurrency === toCurrency) {
      return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 });
    }
    
    const convertedAmount = (amount / rates[fromCurrency] * rates[toCurrency]).toFixed(2);
    return Number(convertedAmount).toLocaleString('en-US', { minimumFractionDigits: 2 });
  };



  return (
    <>
    <div className="p-10 bg-[#252525] min-h-screen xl:mb-0 ">

      <div className="flex items-center justify-between border-b border-[#b7a78b] py-4 my-2 xl:mx-[250px] lg:mx-[150px] md:mx-[20px] mx-[4px] gap-2">
        {/* Currency Selector */}
        <div className="flex items-center">
          <label className="mr-2 text-white">Currency:</label>
          <select
            value={currency}
            onChange={(e) => handleCurrencyChange(e.target.value)}
            className="bg-[#373333] border border-[#373333] text-white p-2 cursor-pointer"
          >
            <option value="NGN">Naira (₦)</option>
            <option value="USD">USD ($)</option>
            <option value="EUR">Euro (€)</option>
            <option value="GBP">Pound (£)</option>
          </select>
        </div>

        <div className="flex items-center gap-4">
          {/* User dropdown */}
          <div className="relative" ref={dropdownRef}>
            <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
              <img src={user} width="27" height="27" alt="User" />
            </div>
            {isOpen && (
              <div
                className="absolute right-0 mt-2 w-48 bg-[#373333] border border-[#373333] shadow-lg"
                onClick={(e) => e.stopPropagation()}
              >
                {isLoggedIn ? (
                  <ul className="py-2 text-sm text-white">
                    <li className="px-4 py-2 hover:bg-[#252525] cursor-pointer">
                      <a href="/dashboard">
                        Profile
                      </a>
                    </li>
                    {/*<li className="px-4 py-2 hover:bg-[#252525] cursor-pointer">Settings</li>*/}
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={logout}>
                      Logout
                    </li>
                  </ul>
                ) : (
                  <ul className="py-2 text-sm text-white">
                    <li className="px-4 py-2 hover:bg-[#252525] cursor-pointer" onClick={() => { 
                      setIsOpen(false); 
                      navigate("/login"); 
                    }}>
                      Login
                    </li>
                      <li className="px-4 py-2 hover:bg-[#252525] cursor-pointer" onClick={() => { 
                        setIsOpen(false); 
                        navigate("/register"); 
                      }}>
                        Register
                      </li>
                    </ul>
                  )}
                  </div>
                )}
              </div>

                  {/* Shopping bag */}
                  <div className="relative cursor-pointer" onClick={onCartToggle}>
                    <img src={shoppingBag} width="27" height="27" alt="shopping bag" />
                    <div className="absolute -top-1 -right-1 bg-[#373333] rounded-full w-5 h-5 flex items-center justify-center">
                      <span className="text-white text-xs">{totalQTY}</span>
                    </div>
                  </div>
                </div>

      </div>

      <div className="flex flex-col lg:flex-row xl:px-[250px] lg:px-[200px] md:px-[150px] px-[10px] mx-auto mt-20">
        {/*<div className="w-full lg:w-1/2 h-[500px]">
          <img src={product.images[0]} alt={product.name} className="w-full h-full object-cover" />
          <img src={product.images[1]} alt={product.name} className="w-full h-full object-cover" />
        </div>*/}

        <div className="w-full lg:w-1/2">
          {/* Slideshow Container */}
          <div className="relative w-full h-96 overflow-hidden shadow-lg border-[30px] border-white">
            {product.images.map((src, index) => (
              <div
                key={index}
                className="absolute w-full h-full transition-all duration-500 ease-in-out"
                style={{
                  transform: `translateX(${(index - activeIndex) * 100}%)`,
                  opacity: index === activeIndex ? 1 : 0.8,
                }}
              >
                <img
                  src={src}
                  alt={`${product.name} - View ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}

            {/* Navigation Dots */}
            <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
              {product.images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setActiveIndex(index)}
                  className={`w-3 h-3 ${
                    index === activeIndex ? "bg-white" : "bg-gray-400"
                  }`}
                  aria-label={`View image ${index + 1}`}
                />
              ))}
            </div>
          </div>

          {/* Thumbnails */}
          <div className="flex gap-2 justify-center mt-4">
            {product.images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className={`w-20 h-20 object-cover cursor-pointer transition duration-300 ${
                  activeIndex === index ? "border-4 border-black scale-105" : "border border-gray-300"
                }`}
                onClick={() => setActiveIndex(index)} // Change slideshow image on click
              />
            ))}
          </div>
        </div>

        <div className="lg:w-1/2 md:px-10 px-4 md:mt-10 md:mt-0 mt-4">
          <p className="xl:text-3xl text-2xl text-white mb-4 uppercase">{product.name}</p>
          <p className="text-3xl font-semibold text-white mb-4">
            {currency === "USD"
              ? "$"
              : currency === "NGN"
              ? "\u20A6"
              : currency === "EUR"
              ? "€"
              : "£"}
            {convertPrice(
              selectedProductDetails[product.id]?.price || product.amount,
              "USD",
              currency,
              rates
            )}

          </p>


          <div className="mb-4">
            <label className="text-white capitalize mb-2 block"> Size</label>
            {product.sizes?.length > 0 ? (
              <select 
                className="bg-transparent border border-[#b7a78b] text-white py-2 px-4 w-full"
                value={selectedSize} 
                onChange={handleSizeChange}
              >
                {product.sizes.map((size, index) => (
                  <option key={index} value={size.size} className="text-black">
                    {size.size}
                  </option>
                ))}
              </select>
            ) : (
              <p className="text-white">No sizes available for this product.</p>
            )}

          </div>

          <p className="text-white capitalize mb-2">quantity</p>
          <div className="flex items-center justify-start gap-2 flex-1 mb-4">
            <button
              onClick={() => handleQuantityChange(-1)}
              className="bg-transparent border border-[#b7a78b] text-white w-[40px] h-[40px] flex items-center justify-center active:scale-90"
            >
              -
            </button>
            <div className="bg-transparent border border-[#b7a78b] text-white font-medium w-[40px] h-[40px] flex items-center justify-center">
              {/*{getCartQuantity(product.id)}*/}
            {quantity}
            </div>
            <button
              onClick={() => handleQuantityChange(1)}
              className="bg-transparent border border-[#b7a78b] text-white w-[40px] h-[40px] flex items-center justify-center active:scale-90"
            >
              +
            </button>
          </div>
          <button
            onClick={onAddToCart}
            className="text-white bg-[#b7a78b] px-6 py-3 mt-4 text-base font-medium uppercase tracking-wider hover:bg-[#9e8e73]"
          >
            Add to Cart
          </button>
          <p className="mt-10 text-white">
            2-3 weeks delivery.
          </p>
        </div>
      </div>
    </div>

    {/* Popup Component */}
    {popupVisible && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 shadow-lg">
          <p className="font-semibold">Please increase the product quantity to at least 1!</p>
          <button 
            className="mt-4 px-4 py-2 bg-[#b7a78b] text-white"
            onClick={() => setPopupVisible(false)}
          >
            Close
          </button>
        </div>
      </div>
    )}



    {popupVisibleSize && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 shadow-lg">
          <p className="font-semibold">Please select a size before adding to the cart!</p>
          <button 
            className="mt-4 px-4 py-2 bg-[#b7a78b] text-white"
            onClick={() => setPopupVisibleSize(false)}
          >
            Close
          </button>
        </div>
      </div>
    )}
  </>


  );
};

export default ProductDetail;