import React, { useState, useEffect } from 'react'
import Sidebar from './components/Sidebar'

const Contact = () => {
	const [users, setUsers] = useState(null);
	const [error, setError] = useState(null);

	
	// const URL = import.meta.env.VITE_BASE_URL;

	useEffect(() => {
	    const fetchUsersData = async () => {
	      try {
	        const response = await fetch('https://sope-backend.vercel.app/users', {
	          method: 'GET',
	          headers: {
	            'Content-Type': 'application/json',
	          },
	        });

	        if (!response.ok) {
	          throw new Error('Failed to fetch users');
	        }

	        const data = await response.json();
	        setUsers(data);  // Set the fetched users to state
	      } catch (err) {
	        setError(err.message);  // Handle any errors
	      }
	    };

	    fetchUsersData();
	}, []);

	if (error) {
	    return <div>Error: {error}</div>;
	}

	if (!users) {
	    return <div>Loading...</div>;
	}

	return (
		<>
			<div className="flex">
		      <Sidebar />
		      <div className="w-[85%]">
		        <div className="px-5 py-4 bg-black text-white">
		          <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
		        </div>
		        <div className="p-20">
		        	<div className="flex justify-center overflow-x-auto ">
					  <table className="min-w-full border border-gray-300">
					    <thead>
					      <tr className="bg-gray-100">
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>Name</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>Email</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>address</th>
					        {/*<th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>Message</th>*/}
					      </tr>
					    </thead>
					    <tbody className="bg-white divide-y divide-gray-300">
					      {/* Table rows */}
					    	{users.map((user, index) => (
						    <tr className="hover:bg-gray-100" key={user._id}>
						    	<td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
						        <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{user.name}</td>
						        <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{user.email}</td>
						        <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{user.addresses}</td>
						    </tr>
						    ))}
					      {/* Add more rows as needed */}
					    </tbody>
					  </table>
					</div>

		        </div>
		      </div>
		    </div>
		</>
	)
}

export default Contact