import React,{ useState, useEffect, useMemo } from "react";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { 
  setDecreaseItemQTY,
  setIncreaseItemQTY,
  setRemoveItemFromCart,
  setClearCartItems,
  setCloseCart,
  selectTotalAmount,
  selectCurrency,
  setCurrency,
} from "../../CartSlice.js";
import CheckoutModal from '../CheckoutModal';


const CartItem = ({ item: { id, title, text, img, color, shadow, price, cartQuantity, currency }, cartItems }) => {
  let subtitle;
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [rates, setRates] = useState({});

  const currencyy = useSelector(selectCurrency);
  const currentCurrency = useSelector(state => state.cart.currency);
  const [convertedPrice, setConvertedPrice] = useState(price);
  const [showLoginAlert, setShowLoginAlert] = useState(false);


  useEffect(() => {
  const convertPrice = async () => {
    if (currentCurrency !== 'USD') {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        const rate = data.rates[currentCurrency];
        const converted = price * rate * cartQuantity;  // Use destructured values
        setConvertedPrice(converted.toFixed(2));
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    } else {
      setConvertedPrice((price * cartQuantity).toFixed(2));
    }
  };

  convertPrice();
}, [currentCurrency, cartQuantity, price]);  // Updated dependencies


  const totalAmount = useSelector(selectTotalAmount);

  const convertedSubtotal = useMemo(() => {
    if (!rates[currentCurrency] || !totalAmount) {
      return totalAmount.toFixed(2);
    }
    
    return (totalAmount * rates[currentCurrency]).toFixed(2);
  }, [totalAmount, currentCurrency, rates]);

  
  const onRemoveItem = () => {
    dispatch(setRemoveItemFromCart({ id }));
  };

  const onIncreaseItemQTY = () => {
    dispatch(setIncreaseItemQTY({ id }));
  };

  const onDecreaseItemQTY = () => {
    dispatch(setDecreaseItemQTY({ id }));
  };

  const onClearCartItems = () => {
    dispatch(setClearCartItems());
  };
  
  const openModal = () => {
    const isLoggedIn = Boolean(localStorage.getItem('token'));

    if (!isLoggedIn) {
      setShowLoginAlert(true); // Show the custom alert
    } else {
      setIsOpen(true);
    }
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  const closeModal = () => {
    setIsOpen(false);
  }


  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setRates(data.rates);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);


  // Currency converter function
  const convertPrice = (amount, fromCurrency, toCurrency, rates) => {
    if (!rates || fromCurrency === toCurrency) {
      return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 });
    }
    
    const convertedAmount = (amount / rates[fromCurrency] * rates[toCurrency]).toFixed(2);
    return Number(convertedAmount).toLocaleString('en-US', { minimumFractionDigits: 2 });
  };

  const currencySymbols = {
    USD: '$',
    NGN: '₦',
    EUR: '€',
    GBP: '£'
  };


  return (
    <>
      <div className="flex items-center justify-between w-full px-1">
        <div className="flex items-center gap-5">
          <div className={`bg-gradient-to-b ${color} ${shadow} relative rounded p-3 hover:scale-105 transition-all duration-75 ease-in-out grid items-center`}>
            <img src={img} height="80" width="80" alt={`img/cart-item/${id}`} className="w-20 h-auto object-fill lg:w-20" />
            {/*<div className='absolute right-1 top-1 blur-theme-effect bg-white/80 text-black text-xs px-1 rounded'>{naira}{price}</div>*/}
          </div>
          <div className="grid items-center gap-4">
            <div className="grid items-center leading-none">
              <h1 className="font-medium text-lg text-[#333] capitalize lg:text-lg xl:text-lg">{title}</h1>
              {/*<p className="text-sm text-white lg:text-xs">{truncatedText}</p>*/}
            </div>
            {/*PRODUCT QUANTITY*/}
            <div className="flex items-center justify-around w-full">
              <button 
                type="button" 
                onClick={onDecreaseItemQTY} 
                className="rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90
                  border border-[#b7a78b]">
                <MinusIcon className="w-5 h-5 lg:w-4 lg:h-4 text-[#333] stroke-[2]" />
              </button>
              <div className="bg-theme-cart rounded text-[#333] font-medium 
                lg:text-xs w-7 h-6 lg:h-5 lg:w-6 flex items-center justify-center">{cartQuantity}</div>
              <button 
                type="button" 
                onClick={onIncreaseItemQTY} 
                className="rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90
                  border border-[#b7a78b]">
                <PlusIcon className="w-5 h-5 lg:w-4 lg:h-4 text-[#333] stroke-[2]" />
              </button>
            </div>
            {/*PRODUCT QUANTITY END*/}
          </div>
        </div>
        <div className="grid items-center gap-5">
          <div className="grid items-center justify-center">
            {/*<h1 className="text-lg lg:text-base text-[#333] font-medium">{naira}{Number(price * cartQuantity).toLocaleString()}</h1>*/}
            <h1 className="text-lg lg:text-base text-[#333] font-medium">
              {currencySymbols[currentCurrency]}{Number(convertedPrice).toLocaleString()}
            </h1>
          </div>
          <div className="grid items-center justify-center">
            <button type="button" className="bg-theme-cart rounded p-1 lg:p-0.5 grid items-center justify-items-center cursor-pointer" onClick={onRemoveItem}>
              <TrashIcon className="w-5 h-5 text-white" />
            </button>
          </div>
        </div>
      </div>


      <div className="fixed bottom-0 bg-[#cecece] w-full px-5 py-2 grid items-center border-t border-[#b7a78b]">
        <div className="flex items-center justify-between">
          <h1 className="text-base text-[#333] font-semibold uppercase">
            SubTotal
          </h1>
          <h1 className="text-base text-[#333] font-semibold rounded bg-theme-cart px-1 py-0.5">
            {/*{currency === 'USD' ? '$' : currency === 'NGN' ? '₦' : currency === 'EUR' ? '€' : '£'}
            {
              currency === 'USD' ? (
                (totalAmount)
              ) : currency === 'NGN' ? (
                (totalAmount / 1677.9).toFixed(2)
              ) : currency === 'EUR' ? (
                (totalAmount / 1782.5).toFixed(2)
              ) : (
                (totalAmount / 2132.2).toFixed(2)
              )
            } */}
            {currencySymbols[currentCurrency]}{Number(convertedSubtotal).toLocaleString()}
          </h1>
        </div>
        <div className="grid items-center gap-2">
          <p className="text-sm text-[#333] font-medium text-center">
            Taxes and Shipping Will Be Calculated At Checkout
          </p>
          <button
            type="button"
            className="bg-[#b7a78b] hover:bg-[#817259] transition text-white px-8 py-4 text-lg "
            onClick={openModal}
          >
            Check Out
          </button>
        </div>
      </div>
      <CheckoutModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        totalAmount={totalAmount}
        onClearCartItems={onClearCartItems}
      />



      {showLoginAlert && (
        

        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
      <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-sm mx-auto">
        <p className="mb-4 text-lg font-semibold">You must be logged in to place an order.</p>
        <div className="flex justify-center gap-4">
          <button
            onClick={() => (window.location.href = "/login")}
            className="bg-[#b7a78b] hover:bg-[#817259] text-white px-4 py-2"
          >
            Login
          </button>
          <button
            onClick={() => setShowLoginAlert(false)}
            className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>

      )}
    </>
  );
};

export default CartItem;