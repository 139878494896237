import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import { 
  Upload, 
  FileText, 
  Link as LinkIcon, 
  Send, 
  Image as ImageIcon 
} from 'lucide-react';

const ExhibitionUploadForm = () => {
  const [exhibitions, setExhibitions] = useState([]);
  const [selectedExhibitionsId, setSelectedExhibitionsId] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [content, setContent] = useState('')
  const [contentId, setContentId] = useState(null);
  const [contents, setContents] = useState([])

  //slides
  const [files, setFiles] = useState([]);
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [link, setLink] = useState('');
  const [brandId, setBrandId] = useState('');
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    success: false,
    error: null
  });



  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
      clipboard: {
      matchVisual: false, // Fixes space issue
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  

// Upload New Content
const handleAboutTextUpload = async () => {
  if (!content.trim()) {
    alert("Please enter some content before uploading.");
    return;
  }

  if (!selectedExhibitionsId) {
    alert("Please select an exhibition.");
    return;
  }

  setLoading(true);

  try {
    const response = await axios.post("https://sope-backend.vercel.app/exhibitiontext", { 
      content,
      exhibitionId: selectedExhibitionsId 
    });

    alert("Content uploaded successfully!");
    setContent(""); // Clear editor
    fetchUploadedContent(); // Refresh content
  } catch (error) {
    alert("Failed to upload content.");
  } finally {
    setLoading(false);
  }
};


// Fetch Content
const fetchUploadedContent = async () => {
  try {
    const response = await fetch("https://sope-backend.vercel.app/exhibitiontext");
    if (response.ok) {
      const data = await response.json();
      if (data.about) {
        setContents([data.about]);
        setContentId(data.about._id); // Store the document ID
      }
    }
  } catch (error) {
    console.error("Error fetching content:", error);
  }
};

// Edit Existing Content
const handleEdit = (con) => {
  setContent(con.content);
  setContentId(con._id);
};

// Update Content
const updateAboutText = async () => {
  if (!contentId) {
    alert("No content selected for updating.");
    return;
  }

  try {
    const response = await axios.put(`https://sope-backend.vercel.app/exhibitiontext/${contentId}`, { content });

    alert("Content updated successfully!");
    fetchUploadedContent(); // Refresh content
  } catch (error) {
    alert("Failed to update content.");
  }
};

  useEffect(() => {
    fetchUploadedContent();
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      // Get both exhibitions and portfolios
      const [exhibitionsRes, portfoliosRes] = await Promise.all([
        fetch('https://sope-backend.vercel.app/exhibitions'),
        fetch('https://sope-backend.vercel.app/exhibitupload')
      ]);
      
      if (exhibitionsRes.ok && portfoliosRes.ok) {
        const exhibitions = await exhibitionsRes.json();
        const portfolios = await portfoliosRes.json();
        
        // Add exhibition data to each portfolio
        const enhancedPortfolios = portfolios.map(portfolio => {
          const matchingExhibition = exhibitions.find(
            exhibition => exhibition._id === portfolio.exhibitonBrand
          );
          
          return {
            ...portfolio,
            exhibitionName: matchingExhibition ? matchingExhibition.name : 'Unknown Exhibition'
          };
        });
        
        setExhibitions(exhibitions);
        setUploadedImages(enhancedPortfolios);
        console.log('Enhanced portfolios:', enhancedPortfolios);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchUploadedImages = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/exhibitupload');
      const data = await response.json();
      console.log('Raw portfolio data:', data);
      
      // Check specifically for exhibitonBrand field
      const hasExhibitionData = data.some(item => 
        item.exhibitonBrand && typeof item.exhibitonBrand === 'object'
      );
      console.log('Has populated exhibition data?', hasExhibitionData);
      
      if (response.ok) {
        setUploadedImages(data);
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };


  const handleExhibitionsSelect = (event) => {
    setSelectedExhibitionsId(event.target.value);
  };

  const handleImageChange = (event) => {
    setImages(event.target.files);
  };

  const handleUpload = async () => {
    if (!selectedExhibitionsId) {
      alert('Please select a exhibition');
      return;
    }

    if (images.length === 0) {
      alert('Please select at least one image to upload');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      Array.from(images).forEach((image) => {
        formData.append('images', image); // 'images' matches the backend's expected field name
      });

      const response = await fetch(`https://sope-backend.vercel.app/exhibitupload/${selectedExhibitionsId}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Images uploaded successfully');
        fetchUploadedImages(); // Refresh the uploaded images
      } else {
        console.error('Error uploading images:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteExhibition = async (exhibitionId) => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/exhibitupload/${exhibitionId}`, { 
        method: 'DELETE' 
      });

      if (response.ok) {
        setUploadedImages(uploadedImages.filter((image) => image.id !== exhibitionId));
        alert('Image has been deleted successfully');
      } else {
        const errorData = await response.json();
        console.error('Error deleting Image:', errorData);
      }
    } catch (error) {
      console.error('Error deleting Image:', error);
    }
  };



  // SLIDES
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    // Create image previews
    const imagePreviews = selectedFiles.map(file => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  
  // Validation
  if (!selectedExhibitionsId || files.length === 0) {
    setUploadStatus({
      loading: false,
      success: false,
      error: 'Please select an exhibition and upload images'
    });
    return;
  }

  // Create FormData
  const formData = new FormData();
  formData.append('text1', text1);
  formData.append('text2', text2);
  formData.append('link', link);
  
  // Append each file
  files.forEach((file) => {
    formData.append('images', file);
  });

  try {
    setUploadStatus({ loading: true, success: false, error: null });

    const response = await axios.post(`https://sope-backend.vercel.app/exhibitslide/${selectedExhibitionsId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    setUploadStatus({ 
      loading: false, 
      success: true, 
      error: null 
    });

    // Reset form
    setFiles([]);
    setText1('');
    setText2('');
    setLink('');
    setSelectedExhibitionsId('');
    setPreviewImages([]);

  } catch (error) {
    setUploadStatus({
      loading: false,
      success: false,
      error: error.response?.data?.error || 'Upload failed'
    });
  }
};





  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-full">
          <div className="px-2 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{ fontFamily: "Muli" }}>welcome, admin</p>
          </div>


          {/*EXHIBITION SLIDE*/}
          
          <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-6 text-center flex items-center justify-center">
              <Upload className="mr-2 text-blue-500" /> Exhibition Slide Upload
            </h2>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Brand ID Input */}
              <div className="p-2">

                <select 
                  className="mr-2 mb-4 cursor-pointer" 
                  style={{ fontFamily: "Muli" }} 
                  value={selectedExhibitionsId} 
                  onChange={(e) => setSelectedExhibitionsId(e.target.value)}
                >
                  <option value="" style={{ fontFamily: "Muli" }}>Select an exhibition</option>
                  {exhibitions.map((exhibition) => (
                    <option key={exhibition._id} value={exhibition._id} style={{ fontFamily: "Muli" }}>
                      {exhibition.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Text Inputs */}
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <label className="block mb-2">First Text</label>
                  <input
                    type="text"
                    value={text1}
                    onChange={(e) => setText1(e.target.value)}
                    placeholder="First text line"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block mb-2">Second Text</label>
                  <input
                    type="text"
                    value={text2}
                    onChange={(e) => setText2(e.target.value)}
                    placeholder="Second text line"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              {/* Link Input */}
              <div>
                <label className="block mb-2 flex items-center">
                  <LinkIcon className="mr-2 text-gray-500" /> Link
                </label>
                <input
                  type="url"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  placeholder="Enter optional link"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* File Upload */}
              <div>
                <label className="block mb-2 flex items-center">
                  <ImageIcon className="mr-2 text-gray-500" /> Upload Images
                </label>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* Image Previews */}
              {previewImages.length > 0 && (
                <div className="grid grid-cols-3 gap-4 mt-4">
                  {previewImages.map((preview, index) => (
                    <img 
                      key={index} 
                      src={preview} 
                      alt={`Preview ${index + 1}`} 
                      className="w-full h-32 object-cover rounded-lg"
                    />
                  ))}
                </div>
              )}

              {/* Upload Status */}
              {uploadStatus.error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                  {uploadStatus.error}
                </div>
              )}

              {uploadStatus.success && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                  Slides uploaded successfully!
                </div>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                disabled={uploadStatus.loading}
                className={`
                  w-full p-3 rounded-lg text-white flex items-center justify-center
                  ${uploadStatus.loading 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-blue-500 hover:bg-blue-600 transition'}
                `}
              >
                {uploadStatus.loading ? (
                  <>Uploading...</>
                ) : (
                  <>
                    <Send className="mr-2" /> Upload Slides
                  </>
                )}
              </button>
            </form>
          </div>











          



          {/* EXHIBITION TEXT */}
          <div>
            <div className="w-full border-b border-gray-300">
              <div className="p-2">
                <h2 className="text-4xl font-semibold mb-4 capitalize">Exhibition location</h2>

                <select 
                  className="mr-2 mb-4 cursor-pointer" 
                  style={{ fontFamily: "Muli" }} 
                  value={selectedExhibitionsId} 
                  onChange={(e) => setSelectedExhibitionsId(e.target.value)}
                >
                  <option value="" style={{ fontFamily: "Muli" }}>Select an exhibition</option>
                  {exhibitions.map((exhibition) => (
                    <option key={exhibition._id} value={exhibition._id} style={{ fontFamily: "Muli" }}>
                      {exhibition.name}
                    </option>
                  ))}
                </select>
                
                <ReactQuill className="h-[60vh] mb-4" value={content} onChange={setContent} />
                
                <button 
                  className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase mt-8"
                  onClick={contentId ? updateAboutText : handleAboutTextUpload}
                >
                  {loading ? 'Uploading...' : contentId ? 'Update Content' : 'Upload Content'}
                </button>
              </div>

              <div className="p-2">
                <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Content</h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full border border-gray-300 mt-10">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">S/N</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">About Write Up</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                      {contents.map((con, index) => (
                        <tr key={con._id} className="hover:bg-gray-100">
                          <td className="px-6 py-4">{index + 1}</td>
                          <td className="px-6 py-4">{con.content}</td>
                          <td className="px-6 py-4">
                            <button 
                              className="bg-blue-500 text-white px-4 py-2 rounded-md" 
                              onClick={() => handleEdit(con)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



          <div className="p-2">
            <h2 className="text-4xl font-semibold mb-4 capitalize">upload images to exhibition</h2>
            <select 
              className="mr-2 cursor-pointer" 
              style={{ fontFamily: "Muli" }} 
              value={selectedExhibitionsId} 
              onChange={handleExhibitionsSelect}
            >
              <option value="" style={{ fontFamily: "Muli" }}>Select an exhibition</option>
              {exhibitions.map((exhibition) => (
                <option key={exhibition._id} value={exhibition._id} style={{ fontFamily: "Muli" }}>
                  {exhibition.name}
                </option>
              ))}
            </select>
            <input 
              style={{ fontFamily: "Muli" }} 
              type="file" 
              onChange={handleImageChange} 
              className="cursor-pointer" 
              multiple // Allow multiple file selection
            />
            <button 
              className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase" 
              style={{ fontFamily: "Muli" }} 
              onClick={handleUpload}
            >
              {loading ? 'Uploading Images...' : 'Upload Images'}
            </button>
          </div>
          <div className="p-2">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Images</h2>
            <div>
              <table className="min-w-full border border-gray-300 mt-10">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>exhibition</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>image</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>delete exhibition</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {uploadedImages.map((image, index) => (
                    <tr className="hover:bg-gray-100" key={index}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ fontFamily: "Muli" }}>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap capitalize" style={{ fontFamily: "Muli" }}>
                        {/*<Link to={`/admin/portfolio/${image.exhibition}`} className="underline text-blue-500">
                          image.exhibitonName
                        </Link>*/}
                        <p>{image.brandName}</p>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img src={image.image} alt="" className="w-1/5" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button 
                          style={{ fontFamily: "Muli" }}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                          onClick={() => handleDeleteExhibition(image.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExhibitionUploadForm;
