import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar'
// import EditBrandForm from './EditBrandForm'

const ExhibitionForm = () => {
  const [exhibitionName, setExhibitionName] = useState('');
  const [exhibitionList, setExhibitionList] = useState([]);
  const [loading, setLoading] = useState(false);

  // const URL = import.meta.env.VITE_BASE_URL;

  const handleExhibitionSubmit = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/exhibitions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: exhibitionName }),
      });

      if (response.ok) {
        const exhibition = await response.json();
        setExhibitionName('')
        alert('New Exhibition successfully created')
        console.log('Exhibition title created:', exhibition);

        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error creating Exhibition title:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating Exhibition title:', error);
    } finally {
      setLoading(false); // End loading after submission completes
    }
  };

  useEffect(() => {
    // Fetch data from the backend
    fetchData();
  }, []);

  const fetchData = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/exhibitions'); // Replace with your backend endpoint
        if (response.ok) {
          const data = await response.json();
          setExhibitionList(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const handleExhibitionUpdated = (updatedExhibition) => {
    const updatedExhibitions = exhibitionList.map((exhibition) => (exhibition._id === updatedExhibition._id ? updatedExhibition : exhibition));
    setExhibitionList(updatedExhibitions);
  };

  const handleDeleteExhibition = async (exhibitionId) => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/exhibitions/${exhibitionId}`, { method: 'DELETE' });
      if (response.ok) {
        setExhibitionList(exhibitionList.filter((exhibition) => exhibition._id !== exhibitionId));
      } else {
        console.error('Error deleting exhibition title:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting exhibition title:', error);
    }
  };



  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-[85%]">
          <div className="px-5 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
          </div>
          <div className="p-2" style={{fontFamily: "Muli"}}>
            <h2 className="text-4xl font-semibold mb-4">Create Exhibition</h2>
            <input 
              className="border mr-4 py-4 w-4/5 pl-2"
              type="text" 
              placeholder="exhibition name" 
              value={exhibitionName}
              onChange={(e) => setExhibitionName(e.target.value)} 
            />
            <button 
              className="text-white bg-black px-10 py-4 uppercase" 
              onClick={handleExhibitionSubmit}>
              {loading ? 'Creating...' : 'Create'}
            </button>

            {loading && (
              <div className="mt-3 text-white"> {/* Loader display */}
                <p>Loading...</p>
              </div>
            )}

            <div className="mt-5 mb-5">
              <h2 className="text-4xl font-semibold mb-4 capitalize">list of exhibitions</h2>
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>exhibition</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>edit exhibition</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete exhibition</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {exhibitionList.length > 0 && exhibitionList.map((exhibition, index) => (
                    <tr className="hover:bg-gray-100" key={exhibition._id}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                      <td 
                        className="px-6 py-4 whitespace-nowrap capitalize" 
                        style={{fontFamily: "Muli"}}
                      >
                        {exhibition.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/*<EditBrandForm exhibitionId={exhibition._id} onExhibitionUpdated={handleExhibitionUpdated} />*/}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button 
                          style={{fontFamily: "Muli"}}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase" 
                          onClick={() => handleDeleteExhibition(exhibition._id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExhibitionForm;
