import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/orders'); // Ensure correct endpoint
      if (response.ok) {
        const data = await response.json();
        setOrders(data.orders);
        setLoading(false);
      } else {
        console.error('Error fetching orders:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/orders/${orderId}`, { method: 'DELETE' });
      if (response.ok) {
        setOrders(orders.filter((order) => order._id !== orderId));
        alert('Order deleted successfully.');
      } else {
        console.error('Error deleting order:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  if (loading) {
    return <div>Loading orders...</div>;
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="p-10">
        <h2 className="text-4xl font-semibold mb-4 capitalize">Orders</h2>

        <table className="min-w-full border border-gray-300 mt-10">
          
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-6 py-3">#</th>
                <th className="px-6 py-3">Payment ID</th>
                <th className="px-6 py-3">Name</th>
                <th className="px-6 py-3">Email</th>
                <th className="px-6 py-3">Phone</th>
                <th className="px-6 py-3">Address</th>
                <th className="px-6 py-3">Country</th>
                <th className="px-6 py-3">Item</th>
                <th className="px-6 py-3">Quantity</th>
                <th className="px-6 py-3">Size</th>
                <th className="px-6 py-3">Price</th>
                <th className="px-6 py-3">Item ID</th>
                {/*<th className="px-6 py-3">Actions</th>*/}
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                const customer = order.customer_details || {};
                let cartItems = [];
                try {
                  cartItems = JSON.parse(order.cart_items);
                } catch (error) {
                  console.error("Error parsing cart items:", error);
                }

                return (
                  <React.Fragment key={order._id}>
                    {cartItems.map((item, idx) => (
                      <tr key={`${order._id}-${idx}`} className="hover:bg-gray-100">
                        {/* Render order details only in the first row */}
                        {idx === 0 && (
                          <>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{index + 1}</td>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{order.payment_intent_id}</td>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{customer.name || "N/A"}</td>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{customer.email}</td>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{customer.phone}</td>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{customer.address}</td>
                            <td rowSpan={cartItems.length} className="px-6 py-4">{customer.country}</td>
                          </>
                        )}

                        {/* Cart item details */}
                        <td className="px-6 py-4">{item.title}</td>
                        <td className="px-6 py-4">{item.cartQuantity}</td>
                        <td className="px-6 py-4">{item.selectedSize}</td>
                        <td className="px-6 py-4">{item.price}</td>
                        <td className="px-6 py-4">{item.id}</td>

                        {/* Render delete button only in the first row */}
                        {/*{idx === 0 && (
                          <td rowSpan={cartItems.length} className="px-6 py-4">
                            <button
                              className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                              onClick={() => handleDeleteOrder(order._id)}
                            >
                              Delete
                            </button>
                          </td>
                        )}*/}
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>

        </table>
      </div>
    </div>
  );
};

export default Orders;
