import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext'
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: "5%",
    transform: 'translate(-50%, -50%)',
    height: '70vh',
    width: '80%',
    maxWidth: '500px',
  },
  overlay: { zIndex: 1000 }
};

Modal.setAppElement('#root');

const stripePromise = loadStripe("pk_test_51QY7kqHOP9yfECqnIyjicC3Ii3qz7h9KEEhBHjRnQFpBT2gQ6cTPJYX91lBlMVV7hBtGuLMeZ7tQtsKFi8ec2E4c00zdS5fP09");

// Separate checkout form component that uses Stripe hooks
const CheckoutForm = ({ totalAmount, onClearCartItems, closeModal }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    phone: '',
    country: '',
  });
  const [addressData, setAddressData] = useState({
    address: '',
    city: '',
    state: '',
    country: '',
  });
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [countries, setCountries] = useState([]);
  // const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const token = localStorage.getItem('token');
  const cartItems = localStorage.getItem('cartItems');
  const [loadingCountries, setLoadingCountries] = useState(true);

  let isTokenValid = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      isTokenValid = decodedToken.exp > currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
    }
  }


  
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://countriesnow.space/api/v0.1/countries/positions');
        const data = await response.json();
        if (data.error) {
          throw new Error(data.msg);
        }
        // Map only the country names
        const countryNames = data.data.map((country) => country.name).sort();
        setCountries(countryNames);
        setLoadingCountries(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);


  useEffect(() => {
    fetchUserData();
  }, [token]);

  const fetchUserData = async () => {
    try {
      const response = await fetch('http://localhost:4000/user/profile', {
      // const response = await fetch('https://sope-backend.vercel.app/user/profile', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch user data');
      const data = await response.json();
      setUserData(data);

      // Check if user has an address
      if (!data.addresses || data.addresses.length === 0) {
        setShowAddressForm(true);
      }
    } catch (error) {
      console.error('Error:', error);
      // logout();
      // navigate('/login');
    } 
  };


  const handleAddAddress = async () => {
    try {
      const { address, country, state, city } = addressData;
      let countryCode = 'Unknown'; // Default fallback

      // Fetch the country code dynamically
      if (country) {
        const apiUrl = `https://restcountries.com/v3.1/name/${encodeURIComponent(country)}`;
        try {
          const response = await fetch(apiUrl);
          if (!response.ok) throw new Error('Failed to fetch country code');
        
          const data = await response.json();
          if (data && data.length > 0) {
            countryCode = data[0].cca2; // ISO 3166-1 alpha-2 country code
          } else {
            console.warn('Country not found in API response');
          }
        } catch (apiError) {
          console.error('Country code API error:', apiError);
        }
      }

      // Send request to backend
      const response = await fetch('https://sope-backend.vercel.app/user/add-address', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          address, country, countryCode, state, city
        })
      });

      if (!response.ok) throw new Error('Failed to add address');
      setShowAddressForm(false);
      
      await fetchUserData();
      console.log('Form submitted successfully');
      setAddressData({
        address: '',
        country: '',
        state: '',
        city: ''
      });
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  

  const handleStripePayment = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe has not loaded yet.');
      return;
    }

    setIsProcessing(true);
    setPaymentError(null);

    try {

      // Create payment intent with additional cart items
      // const response = await axios.post('http://localhost:4000/create-payment-intent', {
      const response = await axios.post('https://sope-backend.vercel.app/create-payment-intent', {
        amount: totalAmount * 100,
        currency: 'usd',
        receipt_email: userData.email,
        cartItems, // Include cart items in the request body
        customerDetails: {
          name: userData.name || 'unknown',
          address: userData.addresses[0].address || 'no address provided',
          email: userData.email || 'no email provided',
          phone: formData.phone,
          country: userData.addresses[0].countryCode || 'no country provided',
        },
        userId: userData._id,
      });

      const { clientSecret } = response.data;

      if (!clientSecret) {
        throw new Error('Failed to get payment intent secret');
      }

      // Confirm card payment
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userData.name || 'unknown',
            email: userData.email || 'No email provided',
            address: {
              line1: userData.addresses[0].address || 'No address provided',
              country: userData.addresses[0].countryCode || 'no country provided',
            },
          },
        },
      });

      if (error) {
        setPaymentError(error.message);
        console.error('Payment failed:', error);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        console.log('Payment successful:', paymentIntent);
        onClearCartItems();
        closeModal();
      }
    } catch (error) {
      setPaymentError(error.message || 'An error occurred during payment processing');
      console.error('Error processing payment:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
    {showAddressForm ? (
        <form onSubmit={(e) => { e.preventDefault(); handleAddAddress(); }} className="space-y-4">
          <input
            type="text"
            name="address"
            value={addressData.address}
            onChange={(e) => setAddressData({ ...addressData, address: e.target.value })}
            placeholder="Enter address"
            className="border border-gray-300 p-4 w-full"
            required
          />
          <input
            type="text"
            name="city"
            value={addressData.city}
            onChange={(e) => setAddressData({ ...addressData, city: e.target.value })}
            placeholder="Enter city"
            className="border border-gray-300 p-4 w-full"
            required
          />
          <input
            type="text"
            name="state"
            value={addressData.state}
            onChange={(e) => setAddressData({ ...addressData, state: e.target.value })}
            placeholder="Enter state"
            className="border border-gray-300 p-4 w-full"
            required
          />
          <select
            name="country"
            value={addressData.country || ''}
            onChange={(e) => setAddressData({ ...addressData, country: e.target.value })}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 p-4"
            disabled={loadingCountries}
          >
            <option value="" disabled>
              {loadingCountries ? 'Loading countries...' : 'Select a country'}
            </option>
            {countries.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
          <button type="submit" className="bg-black text-white px-6 py-3">Save Address</button>
        </form>
      ) : (
        <form onSubmit={handleStripePayment}>
          <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1">
            
            <div>
              <input
                id="message"
                placeholder="Phone Number"
                name="phone"
                className="border border-[#dfd6d6] mb-5 w-[100%] px-6 py-4"
                value={formData.phone}
                onChange={handleInputChange}
                style={{ fontFamily: "Muli" }}
                required
              />
            </div>
            <div className="mb-4">
              <CardElement className="border border-[#dfd6d6] p-4" />
            </div>
          </div>

          <button className="bg-black text-white px-8 py-4 hover:bg-[#4f4a4a] transition" disabled={isProcessing}>
            {isProcessing ? 'Processing...' : `Pay $${totalAmount}`}
          </button>
          {paymentError && <div className="text-red-500 mt-4">{paymentError}</div>}
        </form>
      )}
    </>
  );
};

// Main modal component that wraps the checkout form with Elements provider
const CheckoutModal = ({ isOpen, closeModal, totalAmount, onClearCartItems, cartItems }) => {
  const token = localStorage.getItem('token');
  let isTokenValid = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      isTokenValid = decodedToken.exp > currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Checkout Modal"
    >
      <div className="flex justify-end">
        <button onClick={closeModal}>
          <img width="40" height="40" src="https://img.icons8.com/ios/40/delete-sign--v1.png" alt="delete-sign--v1" />
        </button>
      </div>

      <h2 className="uppercase mb-5 text-3xl font-semibold">checkout</h2>

      {isTokenValid ? (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            totalAmount={totalAmount}
            onClearCartItems={onClearCartItems}
            closeModal={closeModal}
            cartItems={cartItems}
          />
        </Elements>
      ) : (
        <div className="text-center">
          <p className="text-red-500 font-medium mb-4">Your session has expired. Please log in or register to continue.</p>
          <button
            onClick={() => (window.location.href = '/login')}
            className="bg-black text-white px-6 py-3 rounded-md hover:bg-gray-800"
          >
            Login / Register
          </button>
        </div>
      )}
    </Modal>
  );
};

export default CheckoutModal;