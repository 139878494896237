import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';

const UploadProduct = () => {
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [amount, setAmount] = useState('');
  const [files, setFiles] = useState('');
  const [sizes, setSizes] = useState([
    { size: '', price: '' },
    { size: '', price: '' },
    { size: '', price: '' },
  ]); // Tracks the sizes and prices for the product
  const [images, setImages] = useState([]);
  const [products, setProducts] = useState([]);

  // const URL = import.meta.env.VITE_BASE_URL;

  const handleImageChange = (event) => {
    setImages(event.target.files);
  };

  async function createNewPost(ev) {
    ev.preventDefault();
    
    const data = new FormData();
    data.set('name', name);
    data.set('summary', summary);
    data.set('amount', amount);
    // data.set('file', files[0]);

    // For multiple images - make sure to use 'images' to match Multer config
    Array.from(images).forEach((image) => {
      data.append('images', image); // 'images' matches the backend's expected field name
    });

    // Append sizes and prices to the form data
    sizes.forEach((item, index) => {
      data.set(`size_${index}_name`, item.size);
      data.set(`size_${index}_price`, item.price);
    });

    const response = await fetch('https://sope-backend.vercel.app/product', {
    // const response = await fetch('http://localhost:4000/product', {
      method: 'POST',
      body: data,
      credentials: 'include',
    });
    if (response.ok) {
      alert('Product successfully created');
      window.location.reload();
    }
  }

  useEffect(() => {
    fetch('https://sope-backend.vercel.app/product').then((response) => {
    // fetch('http://localhost:4000/product').then((response) => {
      response.json().then((products) => {
        setProducts(products);
      });
    });
  }, []);

  const handleDeletePost = async (productId) => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/product/${productId}`, { method: 'DELETE' });
      if (response.ok) {
        setProducts(products.filter((product) => product._id !== productId));
        alert('Product has been deleted successfully');
      } else {
        console.error('Error deleting product:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSizeChange = (index, key, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[index][key] = value;
    setSizes(updatedSizes);
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-[85%]">
          <div className="px-5 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{ fontFamily: "Muli" }}>Welcome, admin</p>
          </div>
          <div className="p-10">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Upload Product to Shop</h2>

            <input
              type="file"
              multiple
              onChange={handleImageChange} 
              className="cursor-pointer"
            />

            <input
              type="text"
              placeholder="Product Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-2 w-[80%] p-2 border border-gray-300 rounded"
            />

            <textarea
              placeholder="Product Description"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              className="mt-2 w-[80%] p-2 border border-gray-300 rounded"
            ></textarea>

            <input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-2 w-[80%] p-2 border border-gray-300 rounded"
            />

            <h3 className="mt-4">Product Sizes and Prices</h3>
            {sizes.map((sizeItem, index) => (
              <div key={index} className="mt-2 flex gap-4">
                <input
                  type="text"
                  placeholder={`Size ${index + 1}`}
                  value={sizeItem.size}
                  onChange={(e) => handleSizeChange(index, 'size', e.target.value)}
                  className="w-[35%] p-2 border border-gray-300 rounded"
                />
                <input
                  type="number"
                  placeholder={`Price for Size ${index + 1}`}
                  value={sizeItem.price}
                  onChange={(e) => handleSizeChange(index, 'price', e.target.value)}
                  className="w-[35%] p-2 border border-gray-300 rounded"
                />
              </div>
            ))}

            <button
              className="text-white bg-black px-10 py-4 uppercase mt-8"
              onClick={createNewPost}
            >
              Upload Product
            </button>
          </div>
          <div className="px-10 pb-10">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Products</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300 mt-10">
                <thead>
                  <tr className="bg-gray-100">
                    <th
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      style={{ fontFamily: "Muli" }}
                    >
                      s/n
                    </th>
                    <th
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      style={{ fontFamily: "Muli" }}
                    >
                      product name
                    </th>
                    <th
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      style={{ fontFamily: "Muli" }}
                    >
                      description
                    </th>
                    <th
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      style={{ fontFamily: "Muli" }}
                    >
                      sizes and prices
                    </th>
                    <th
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      style={{ fontFamily: "Muli" }}
                    >
                      image
                    </th>
                    <th
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      style={{ fontFamily: "Muli" }}
                    >
                      delete
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {products.map((product, index) => (
                    <tr className="hover:bg-gray-100" key={index}>
                      <td
                        className="px-6 py-4 whitespace-nowrap"
                        style={{ fontFamily: "Muli" }}
                      >
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{product.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{product.summary}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* Display sizes and their prices */}
                        {product.sizes.map((size, sizeIndex) => (
                          <div key={sizeIndex} className="mb-2">
                            <span>
                              {size.size}: ${size.price}
                            </span>
                          </div>
                        ))}
                      </td>
                      {/*<td className="px-6 py-4 whitespace-nowrap">{product.amount}</td>*/}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img src={product.images[0]} alt="" className="w-16 h-16 object-cover" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          style={{ fontFamily: "Muli" }}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                          onClick={() => handleDeletePost(product._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default UploadProduct;
