import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from "../context/AuthContext";
import { 
  setAddItemToCart, 
  setOpenCart, 
  selectTotalQTY,
  selectCurrency,
  setCurrency
} from '../CartSlice';
import shoppingBag from '../assets/shopping-bag.png';
import user from '../assets/user.png';

const ShopWrapper = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisibleSize, setPopupVisibleSize] = useState(false); // State for popup visibility
  const dispatch = useDispatch();
  const totalQTY = useSelector(selectTotalQTY);
  const currency = useSelector(selectCurrency);
  // const [currency, setLocalCurrency] = useState('USD'); // Local state for the dropdown
  const [rates, setRates] = useState({});
  // const [selectedSizes, setSelectedSizes] = useState({});
  const [selectedProductDetails, setSelectedProductDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { token, logout } = useAuth(); // Get token and logout function
  const dropdownRef = useRef(null);
  const isLoggedIn = !!token;

  const navigate = useNavigate();

  const handleCurrencyChange = (newCurrency) => {
    dispatch(setCurrency(newCurrency));
  };

  const handleSizeChange = (e, product) => {
    const selectedSize = product.sizes.find(size => size.size === e.target.value);
    setSelectedProductDetails(prevState => ({
      ...prevState,
      [product.id]: {
        size: selectedSize.size,
        price: selectedSize?.price || product.amount,
      },
    }));
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  // WORKS
  const fetchExchangeRates = async () => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      const data = await response.json();
      setRates(data.rates);  // Store exchange rates
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  };

  const onCartToggle = () => {
    dispatch(setOpenCart({ cartState: true }));
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/product');
      // const response = await fetch('http://localhost:4000/product');
      // console.log('Response status:', response.status)
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
      } else {
        console.error('Error fetching products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchExchangeRates();
  }, []);

  const onAddToCart = (item) => {
    
    const sizeDetails = selectedProductDetails[item.id];
    const quantity = getCartQuantity(item.id);

    // Check if size is selected
    if (!sizeDetails || !sizeDetails.size) {
      setPopupVisibleSize(true); // Show size selection popup
      return;
    }

    if (quantity < 1) {
      setPopupVisible(true); // Show popup if quantity is 0
    } else {

      const convertedPrice = convertPrice(sizeDetails.price, "USD", currency, rates);

      const itemWithDetails = {
        ...item,
        cartQuantity: quantity || 1,
        selectedSize: sizeDetails.size,
        price: sizeDetails.price,
        originalPrice: sizeDetails.price, // Store original USD price
        currency: currency // Store selected currency
      };

      try {
        dispatch(setAddItemToCart(itemWithDetails)); // Dispatch add to cart action
        dispatch(setOpenCart({ cartState: true })); // Open cart
      } catch (error) {
        console.error("Error adding product to cart:", error); // Log any dispatch errors
      }
    }
  };



  const updateCart = (product, change) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item.id === product.id);

      if (existingItem) {
        const updatedCart = prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: Math.max(0, item.quantity + change) }
            : item
        ).filter(item => item.quantity > 0);
        return updatedCart;
      } else if (change > 0) {
        return [...prevCart, { ...product, quantity: change }];
      }

      return prevCart;
    });
  };

  const getCartQuantity = (productId) => {
    const item = cart.find(item => item.id === productId);
    return item ? item.quantity : 0;
  };

  const convertPrice = (amount, fromCurrency, toCurrency, rates) => {
    if (!rates || fromCurrency === toCurrency) {
      return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 });
    }
    
    const convertedAmount = (amount / rates[fromCurrency] * rates[toCurrency]).toFixed(2);
    return Number(convertedAmount).toLocaleString('en-US', { minimumFractionDigits: 2 });
  };




  return (
    <>
      <div className="xl:p-20 lg:p-20 md:p-10 p-5 ">

        {products.length === 0 ? (
          <p className="flex justify-center items-center h-[400px] capitalize text-lg text-[#b7a78b]">There are no products currently available.</p>
        ) : (
            <>
            <div className="flex items-center justify-between border-b border-[#b7a78b] py-4 my-2 xl:mx-[170px] lg:mx-[150px] md:mx-[20px] mx-[4px] gap-2">
              {/* Currency Selector */}
              <div className="flex items-center justify-between w-full">
                {/* Currency selector */}
                <div className="flex items-center">
                  {/*<label className="mr-2 text-white">Currency:</label>
                  <select
                    value={currency}
                    onChange={(e) => handleCurrencyChange(e.target.value)}
                    className="bg-[#373333] border border-[#373333] text-white p-2 cursor-pointer"
                  >
                    <option value="USD">USD ($)</option>
                    <option value="NGN">Naira (₦)</option>
                    <option value="EUR">Euro (€)</option>
                    <option value="GBP">Pound (£)</option>
                  </select>*/}
                </div>

                {/* User and Cart container */}
                <div className="flex items-center gap-4">
                  {/* User dropdown */}
                  <div className="relative" ref={dropdownRef}>
                    <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
                      <img src={user} width="27" height="27" alt="User" />
                    </div>
                    {isOpen && (
                      <div
                        className="absolute right-0 mt-2 w-48 bg-[#373333] border border-[#373333] shadow-lg"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {isLoggedIn ? (
                          <ul className="py-2 text-sm text-white">
                            <li className="px-4 py-2 hover:bg-[#252525] cursor-pointer">
                              <a href="/dashboard">
                                Profile
                              </a>
                            </li>
                            {/*<li className="px-4 py-2 hover:bg-[#252525] cursor-pointer">Settings</li>*/}
                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={logout}>
                              Logout
                            </li>
                          </ul>
                        ) : (
                          <ul className="py-2 text-sm text-white">
                            <li className="px-4 py-2 hover:bg-[#252525] cursor-pointer" onClick={() => { 
                              setIsOpen(false); 
                              navigate("/login"); 
                            }}>
                              Login
                            </li>
                            <li className="px-4 py-2 hover:bg-[#252525] cursor-pointer" onClick={() => { 
                              setIsOpen(false); 
                              navigate("/register"); 
                            }}>
                              Register
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Shopping bag */}
                  {/*<div className="relative cursor-pointer" onClick={onCartToggle}>
                    <img src={shoppingBag} width="27" height="27" alt="shopping bag" />
                    <div className="absolute -top-1 -right-1 bg-[#373333] rounded-full w-5 h-5 flex items-center justify-center">
                      <span className="text-white text-xs">{totalQTY}</span>
                    </div>
                  </div>*/}

                </div>
              </div>
            </div>

            

          <div
            className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4 
            xl:mx-[170px] lg:mx-[100px] md:mx-[50px] sm:mx-[20px] mx-[10px]"
          >
            {products.map((product) => (
              <div key={product.id} className="p-4 cursor-pointer">
                <Link to={`/prints/${product.slug}`}>
                  <div className="w-full h-[300px] sm:h-[350px] overflow-hidden mb-2 border-[30px] border-white">
                    <img
                      src={product.images[0]}
                      alt={product.name}
                      className="w-full h-full object-cover mb-2"
                    />
                  </div>
                </Link>
                <div className="bg-[#373333] p-4 sm:p-2">
                  {/*<div className="border-b border-[#b7a78b] py-2">*/}
                  <div className="py-2">
                    <p className="text-white text-center font-semibold text-[20px] sm:text-[24px] mb-2 capitalize text-left">
                      {product.name}
                    </p>
                  </div>
                  {/*<div className="py-2">
                    <p className="text-white mb-2 text-left">
                      {product.summary.length > 100
                        ? product.summary.substring(0, 100) + "..."
                        : product.summary}
                    </p>
                  </div>*/}
                  <div className="flex flex-wrap items-center justify-between gap-3">
                    {/*<div className="flex-1 text-left">
                      <p className="text-white font-semibold text-lg sm:text-xl">
                        {currency === "USD"
                          ? "$"
                          : currency === "NGN"
                          ? "\u20A6"
                          : currency === "EUR"
                          ? "€"
                          : "£"}
                        {convertPrice(
                          selectedProductDetails[product.id]?.price || product.amount,
                          "USD",
                          currency,
                          rates
                        )}
                      </p>
                    </div>
                    <div>
                      <select
                        className="bg-transparent border border-[#b7a78b] text-white py-2 px-4 w-[90px] sm:w-[100px] cursor-pointer"
                        value={selectedProductDetails[product.id]?.size || ""}
                        onChange={(e) => handleSizeChange(e, product)}
                      >
                        <option value="" disabled>
                          Size
                        </option>
                        {product.sizes.map((size, index) => (
                          <option key={index} value={size.size} className="text-black">
                            {size.size}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex items-center justify-center gap-2 flex-1">
                      <button
                        onClick={() => updateCart(product, -1)}
                        className="bg-[#b7a78b] text-white w-6 h-6 flex items-center justify-center active:scale-90"
                      >
                        -
                      </button>
                      <div className="bg-[#b7a78b] text-white font-medium w-7 h-6 flex items-center justify-center">
                        {getCartQuantity(product.id)}
                      </div>
                      <button
                        onClick={() => updateCart(product, 1)}
                        className="bg-[#b7a78b] text-white w-6 h-6 flex items-center justify-center active:scale-90"
                      >
                        +
                      </button>
                    </div>*/}
                    {/*<div className="flex-1 text-right">
                      <button
                        type="button"
                        className="w-full text-white bg-transparent transition-all duration-300 border border-[#b7a78b] text-[12px] 
                          font-medium py-2 hover:bg-[#b7a78b] hover:text-white hover:scale-105 hover:shadow-lg uppercase tracking-[0.2em]"
                        onClick={() =>
                          onAddToCart({
                            id: product.id,
                            title: product.name,
                            text: product.summary,
                            img: product.cover,
                            price: product.amount,
                            quantity: getCartQuantity(product.id),
                            currency: currency,
                          })
                        }
                      >
                        Add to Cart
                      </button>
                    </div>*/}
                  </div>
                </div>
              </div>
            ))}
          </div>

          </>
        )}

        {/* Popup Component */}
        {popupVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 shadow-lg">
              <p className="font-semibold">Please increase the product quantity to at least 1!</p>
              <button 
                className="mt-4 px-4 py-2 bg-[#b7a78b] text-white"
                onClick={() => setPopupVisible(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}



        {popupVisibleSize && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 shadow-lg">
              <p className="font-semibold">Please select a size before adding to the cart!</p>
              <button 
                className="mt-4 px-4 py-2 bg-[#b7a78b] text-white"
                onClick={() => setPopupVisibleSize(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default ShopWrapper;
