import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Sidebar from './components/Sidebar'

const SubbrandImageUpload = () => {
  const [subbrands, setSubbrands] = useState([]);
  const [selectedSubbrandId, setSelectedSubbrandId] = useState('');
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  // const URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    const fetchSubbrands = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/subbrands'); // Adjust the URL
        const data = await response.json();
        setSubbrands(data);
      } catch (error) {
        console.error('Error fetching subbrands:', error);
      }
    };

    fetchSubbrands();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/uploads');
        if (response.ok) {
          const data = await response.json();
          setImages(data);
        } else {
          console.error('Error fetching images');
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages()
  }, [])

  const handleSubbrandSelect = (event) => {
    setSelectedSubbrandId(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    setIsLoading(true)
    
    if (!selectedSubbrandId) {
      alert('Please select a subbrand');
      setIsLoading(false)
      return;
    }

    try {
      const formData = new FormData();
      formData.append('image', image);

      const response = await fetch(`https://sope-backend.vercel.app/uploads/${selectedSubbrandId}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Image uploaded successfully');
        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error uploading image:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsLoading(false)
    }
  };

  // DELETE PICTURES FROM THE SUB PORTFOLIO
  const handleDeleteSubPortfolio = async (subportfolioId) => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/uploads/${subportfolioId}`, { method: 'DELETE' });
      if (response.ok) {
        setImages(images.filter((image) => image._id !== subportfolioId));
        alert('Image has been deleted successfully')
      } else {
        console.error('Error deleting brand:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      
      <div className="w-[85%]">
          <div className="px-5 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
          </div>
          <div className="p-10">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Upload Image to Subbrand</h2>
            <select 
              className="mr-2 cursor-pointer" 
              style={{fontFamily: "Muli"}} 
              value={selectedSubbrandId} 
              onChange={handleSubbrandSelect}
            >
              <option 
                value="" 
                style={{fontFamily: "Muli"}}
              >Select a subbrand</option>
              {subbrands.map((subbrand) => (
                <option style={{fontFamily: "Muli"}} key={subbrand._id} value={subbrand._id}>
                  {subbrand.name}
                </option>
              ))}
            </select>
            <input 
              style={{fontFamily: "Muli"}} 
              type="file" 
              onChange={handleImageChange} 
              className="cursor-pointer"
            />
            <button 
              className="text-white bg-black px-10 py-4 uppercase" 
              style={{fontFamily: "Muli"}} 
              onClick={handleUpload}>
              Upload Image
            </button>
          </div>
          <div className="px-10 pb-10">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Images</h2>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div>
                <table className="min-w-full border border-gray-300 mt-10">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>portfolio</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>image</th>
                      <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete portfolio</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-300">
                    {images.map((image, index) => (
                      <tr className="hover:bg-gray-100" key={index}>
                        <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap capitalize" style={{fontFamily: "Muli"}}>
                          <Link to={`/admin/portfolio/${image.subbrand}`} className="underline text-blue-500">
                            {image.subbrandName}
                          </Link>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap"><img src={image.image} alt="" className="w-1/5" /></td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button 
                            style={{fontFamily: "Muli"}}
                            className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                            onClick={() => handleDeleteSubPortfolio(image._id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        </div>
  );
};

export default SubbrandImageUpload;