import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import arrowLeft from '../../assets/arrow-left.png';
import arrowRight from '../../assets/arrow-right.png';
import close from '../../assets/close.png';
import { Play, X } from 'lucide-react';

const ExhibitionDetail = () => {
  
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
	const [images, setImages] = useState([]);
	const [brandName, setBrandName] = useState('')
	const {brandName: urlBrandName} = useParams()
	const [isOpen, setIsOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);

  const [columns, setColumns] = useState(4);
  const [imageColumns, setImageColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [exhibitionId, setExhibitionId] = useState('');

  const [exhibitionContent, setExhibitionContent] = useState('');
  const [exhibitionName, setExhibitionName] = useState('');

  const [videos, setVideos] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState(true);


  useEffect(() => {
    const fetchVideos = async () => {
      try {
        if (!urlBrandName) return; // Ensure exhibitionName is available

        const response = await fetch(`https://sope-backend.vercel.app/exhibitslide/${urlBrandName}`);
        if (!response.ok) throw new Error('Failed to fetch videos');

        const data = await response.json();
        setVideos(
          data.map((slide) => ({
            thumbnail: slide.image,
            link: slide.link,
            text1: slide.text1,
            text2: slide.text2,
          }))
        );
      } catch (error) {
        console.error('Error fetching exhibition slides:', error);
      } finally {
        setLoadingVideos(false);
      }
    };

    fetchVideos();
  }, [urlBrandName]);



  // Responsive column adjustment
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) setColumns(1);
      else if (screenWidth < 768) setColumns(2);
      else if (screenWidth < 1024) setColumns(3);
      else setColumns(4);
    };

    // Initial setup and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Distribute images across columns
  useEffect(() => {
    if (images.length === 0) return;

    // Create empty columns
    const newColumns = Array.from({ length: columns }, () => []);

    // Distribute images across columns
    images.forEach((image, index) => {
      const columnIndex = index % columns;
      newColumns[columnIndex].push({ ...image, index });
    });

    setImageColumns(newColumns);
  }, [images, columns]);



  useEffect(() => {
    fetchImages();
    fetchBrand();
  }, [urlBrandName]);

  const fetchImages = async () => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/exhibitupload/${urlBrandName}`);
      // const response = await fetch(`http://localhost:4000/exhibitupload/${urlBrandName}`);
      if (response.ok) {
        const data = await response.json();
        setImages(
          data.map((image) => ({
            src: image.image, // Use 'src' instead of 'original' for react-photo-gallery
            alt: image.alt,
          }))
        );
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchBrand = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/exhibitions');
      // const response = await fetch('http://localhost:4000/exhibitions');
      if (response.ok) {
        const data = await response.json();

      	const matchingBrand = data.find(brand => brand.brandName === urlBrandName); // Assuming 'id' is the property to match
      	if (matchingBrand) {
          setBrandName(matchingBrand.brandName);
          setExhibitionId(matchingBrand._id);
        }

      } else {
        console.error('Error fetching brands');
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };


  useEffect(() => {
    const fetchExhibitionIdAndContent = async () => {
      try {
        // First, get the exhibitionId using the exhibitionName (slug)
        const exhibitionResponse = await fetch(`https://sope-backend.vercel.app/exhibitupload/${urlBrandName}`);
        if (!exhibitionResponse.ok) {
          const errorData = await exhibitionResponse.json();
          throw new Error(errorData.error);
        }
        
        const exhibitionData = await exhibitionResponse.json();
        if (!exhibitionData.length) {
          throw new Error('Exhibition data not found');
        }

        const exhibitionId = exhibitionData[0].exhibitionBrand; // Assuming exhibitionBrand is the ID
        console.log('Exhibition ID:', exhibitionId);

        // Then, use the exhibitionId to fetch the exhibition text
        const textResponse = await fetch(`https://sope-backend.vercel.app/exhibitiontext/${exhibitionId}`);
        if (!textResponse.ok) {
          const errorData = await textResponse.json();
          throw new Error(errorData.error);
        }

        const textData = await textResponse.json();
        setExhibitionContent(textData.content);
        setExhibitionName(textData.exhibitionName);

        console.log('Exhibition Text Data:', textData);
      } catch (error) {
        console.error('Error fetching exhibition data:', error.message);
      }
    };

    fetchExhibitionIdAndContent();
  }, [urlBrandName]);



  const openModal = (link) => {
    setCurrentVideoUrl(link);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoUrl('');
  };

  // Open the lightbox
  const openLightbox = (index) => {
      setCurrentImageIndex(index);
      setLightboxOpen(true);
  };

  const closeLightbox = () => {
      setLightboxOpen(false);
      setCurrentImageIndex(0);
  };

  const showNextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPrevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };


  return (
    <>


      <div className="bg-[#252525] min-h-screen">
        <div className="xl:h-[400px] lg:h-[400px] md:h-[400px] h-[300px]  
          xl:px-20 xl:py-4 px-4 py-4">
          {loadingVideos ? (
            <p>Loading videos...</p>
          ) : videos.length > 0 ? (
            <Swiper 
              spaceBetween={30}
              pagination={{ clickable: true }} 
              centeredSlides={true}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper">
              
              {videos.map((video, index) => (
                <SwiperSlide key={index}>
                  <div className="flex justify-center items-center xl:px-40 md:px-20 px-5">
                    <div className="relative w-full">
                      <div 
                        className="relative cursor-pointer w-full xl:h-[500px] md:h-[500px] h-[400px]" 
                        onClick={() => openModal(video.link)}
                      >
                        {/* Background Image */}
                        <img 
                          src={video.thumbnail}
                          alt={`Video thumbnail ${index + 1}`} 
                          className="absolute w-full h-full object-cover"
                        />

                        {/* Dark Overlay & Centered Play Button */}
                        <div className="absolute inset-0 bg-black/30 bottom-[20%] flex items-center justify-center">
                          <Play size={64} className="text-white" />
                        </div>

                        {/* Right-Aligned Text */}
                        <div className="absolute bottom-[27%] right-4 text-white font-medium bg-black/50 px-4 py-2 rounded text-right">
                          <p className="uppercase font-semibold xl:text-xl lg:text-xl md:text-xl text-lg tracking-[.12em]">
                            {video.text1}
                          </p>
                          <p className="uppercase xl:text-base lg:text-base md:text-base text-[14px] tracking-[.12em]">
                            {video.text2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p className="text-center text-white">No videos available.</p>
          )}
        </div>


          <div className="bg-[#252525] min-h-screen py-8 xl:px-[240px] lg:px-[240px] px-[30px]">
            <div className="py-4 px-2 xl:px-0 lg:px-0 md:px-0 mb-2">
              
              <ReactQuill
                className="capitalize text-[#b7a78b] xl:text-xl text-lg font-semibold tracking-[.11em]" 
                value={exhibitionContent}
                readOnly={true} // Makes it non-editable
                theme="bubble" // Uses a minimal theme for display
              />
              

            </div>
            <div className="flex gap-4">
                {imageColumns.map((column, colIndex) => (
                  <div key={colIndex} className="flex-1 flex flex-col gap-4">
                  {column.map((image, imgIndex) => (
                    <div key={imgIndex} className="w-full cursor-pointer" onClick={() => openLightbox(image.index)}>
                      <img
                        src={image.src}
                        alt={`Gallery ${image.index + 1}`}
                        className="w-full object-cover shadow-md hover:shadow-xl transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                  ))}
                  
                  </div>
                ))}
            </div>
          </div>

          {/* Lightbox */}
          {lightboxOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
              <div className="relative flex items-center justify-center w-full">
                <button
                  onClick={showPrevImage}
                  className="absolute left-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
                >
                  <img width="24" height="24" src={arrowLeft}/>
                </button>

                <img
                  src={images[currentImageIndex]}
                  alt={`Lightbox ${currentImageIndex + 1}`}
                  className="max-w-full max-h-screen"
                />

                <button
                  onClick={showNextImage}
                  className="absolute right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
                >
                  <img width="24" height="24" src={arrowRight}/>
                </button>

                <button
                  onClick={closeLightbox}
                  className="absolute top-4 right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
                >
                  <img width="24" height="24" src={close}/>
                </button>
              </div>
            </div>
          )}

      </div>



      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div 
            className="absolute inset-0 bg-black/90 backdrop-blur-sm"
            onClick={closeModal}
          />
          
          <div className="relative w-[95vw] h-[90vh] bg-black rounded-lg overflow-hidden">
            <button 
              onClick={closeModal}
              className="absolute right-4 top-4 z-50 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
            >
              <X className="h-6 w-6 text-white" />
            </button>
            
            <iframe
              src={currentVideoUrl}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              className="w-full h-full"
              title="Video Content"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ExhibitionDetail;
