import React, { useState, useEffect } from "react";
import $ from "jquery";
import Cart from '../commerce/Cart'
import shoppingBag from '../assets/shopping-bag.png'
import { useDispatch, useSelector } from 'react-redux';
import { selectTotalQTY, setOpenCart } from '../CartSlice.js';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ifCartState, setIfCartState] = useState(false);
  const dispatch = useDispatch();
  const totalQTY = useSelector(selectTotalQTY);
  const [navState, setNavState] = useState(false);
  const [brands, setBrands] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false); // Mobile Portfolio dropdown state
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    console.log("clicked")
  };

  // mobile navigation

  const onCartToggle = () => {
    setIfCartState(!ifCartState);
    dispatch(setOpenCart({
      cartState: true
    }))
  }


  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/brands');
        // const response = await fetch('http://localhost:4000/brands');
        const data = await response.json();
        
        if (data.length > 1) {
          const temp = data[0];
          data[0] = data[1];
          data[1] = temp;
        }

        if (data.length >= 4) {
          const title = data[3].name || '';
          const slug = title
            .toLowerCase()
            .replace(/\//g, '') // Remove all slashes
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/-{2,}/g, '-') 
            .replace(/^-|-$/g, '');        
          data[3] = { ...data[3], slug };
        }
        
        setBrands(data);
      } catch (error) {
        console.error('Error fetching portfolio titles:', error);
      }
    };
    fetchBrands();
  }, []);



  // FETCH EXHIBITION
  useEffect(() => {
    const fetchExhibitions = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/exhibitions');
        // const response = await fetch('http://localhost:4000/exhibitions');
        const data = await response.json();
        
        if (data.length > 1) {
          const temp = data[0];
          data[0] = data[1];
          data[1] = temp;
        }

        if (data.length >= 4) {
          const title = data[3].name || '';
          const slug = title
            .toLowerCase()
            .replace(/\//g, '') // Remove all slashes
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/-{2,}/g, '-') 
            .replace(/^-|-$/g, '');        
          data[3] = { ...data[3], slug };
        }
        
        setExhibitions(data);
      } catch (error) {
        console.error('Error fetching exhibition titles:', error);
      }
    };
    fetchExhibitions();
  }, []);


  const subscribeForm = async (e) => {
    e.preventDefault()
    try {
      // const response = await fetch('http://localhost:4000/subscribe', {
      const response = await fetch('https://sope-backend.vercel.app/subscribe', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
      if (response.ok) {
          console.log('Form submitted successfully');
          alert('Form submitted successfully')
          // Reset the form data after successful submission
          setFormData({
            name: '',
            email: '',
          });
        } else {
          console.error('Form submission failed');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
  }

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };


  // Custom easing function: easeInOutExpo
  function easeInOutExpo(t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  }

  // Smooth scroll with custom easing
  useEffect(() => {
    $(function () {
      $("a.page-scroll").bind("click", function (event) {
        const $anchor = $(this);
        const target = $($anchor.attr("href")).offset().top;
        const duration = 1500;
        const start = $(window).scrollTop();
        const change = target - start;
        const increment = 20;
        let currentTime = 0;

        function animateScroll() {
          currentTime += increment;
          const val = easeInOutExpo(currentTime, start, change, duration);
          $(window).scrollTop(val);
          if (currentTime < duration) {
            setTimeout(animateScroll, increment);
          }
        }
        animateScroll();
        event.preventDefault();
      });
    });
  }, []);

  return (
    <>
      <Cart />
      <nav className="shadow-lg bg-[#252525]">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center xl:h-[100px] lg:h-[100px] h-[70px]">
            {/* Logo */}
            <div className="xl:text-3xl lg:text-2xl md:text-2xl text-2xl uppercase text-[#e4e4e3] font-semibold tracking-[0.1em]">
              <a href="/">Sope Adelaja</a>
            </div>
            {/* Menu Items */}
            <nav className="hidden md:flex xl:space-x-12 space-x-8 items-center">
              <a href="/" className="xl:text-[12px] lg:text-[11px] md:text-[11px] text-[11px] text-[#e4e4e3] hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                Overview
              </a>
              <div className="relative group h-full flex items-center">
                <a
                  href="javascript:void(0)"
                  className="xl:text-[12px] lg:text-[11px] md:text-[11px] text-[11px] text-[#e4e4e3] hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white"
                >
                  portfolios
                </a>
                <div
                  className="absolute hidden left-0 top-full group-hover:block text-[#e4e4e3] text-[11px] p-2 shadow-lg
                  overflow-hidden transition-all duration-500 ease-in-out bg-[#252525] z-50"
                >
                  {brands.map((brand) => (
                    <a
                      href={`/portfolios/${brand.name
                        .replace(/\//g, '--') // Replace slashes with double hyphens
                        .replace(/\s+/g, '-') // Replace spaces with single hyphens
                        .replace(/-{2,}/g, '--')
                        .toLowerCase()}`} // Convert to lowercase

                      key={brand._id}
                      className="capitalize block px-4 text-[#e4e4e3] py-2 text-[11px] hover:bg-gray-800"
                    >
                      {/*{brand.name === 'ad tear sheet' ? 'ad/tear sheet' : brand.name}*/}
                      {brand.name}
                    </a>
                  ))}
                  <a href="/portfolios/motions"
                    className="capitalize block px-4 py-2 text-[#e4e4e3] text-[11px] hover:bg-gray-800">motions</a>
                </div>
              </div>
              <a href="/thoughts" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-[#e4e4e3] hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                thoughts
              </a>
              {/*<a href="/prints" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-[#e4e4e3] hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                prints
              </a>*/}
              <div className="relative group h-full flex items-center">
                <a href="javascript:void(0)" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-[#e4e4e3] hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                  exhibitions
                </a>
                <div className="absolute hidden left-0 top-full w-[150px] group-hover:block text-[#e4e4e3] p-2 shadow-lg
                  overflow-visible transition-all duration-500 ease-in-out bg-[#252525] z-50">
                  {/*<a href="/exhibitions/in-her-hands" className="block px-4 py-2 text-[11px] hover:bg-gray-800 capitalize">
                    in her hands
                  </a>*/}
                  {exhibitions.map((exhibition) => (
                    <a
                      href={`/exhibitions/${exhibition.name
                        .replace(/\//g, '--') // Replace slashes with double hyphens
                        .replace(/\s+/g, '-') // Replace spaces with single hyphens
                        .replace(/-{2,}/g, '--')
                        .toLowerCase()}`} // Convert to lowercase

                      key={exhibition._id}
                      className="capitalize block px-4 text-[#e4e4e3] py-2 text-[11px] hover:bg-gray-800"
                    >
                      {/*{brand.name === 'ad tear sheet' ? 'ad/tear sheet' : brand.name}*/}
                      {exhibition.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="relative group h-full flex items-center">
                <a href="javascript:void(0)" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                  about
                </a>
                <div className="absolute hidden left-0 top-full w-[150px] group-hover:block text-[#e4e4e3] p-2 shadow-lg
                  overflow-visible transition-all duration-500 ease-in-out bg-[#252525] z-50">
                  <a href="/about/on-set" className="block px-4 py-2 text-[#e4e4e3] text-[12px] hover:bg-gray-800 capitalize">
                    on set
                  </a>
                  <a href="/learn-more" className="block px-4 py-2 text-[#e4e4e3] text-[12px] hover:bg-gray-800 capitalize">
                    bio
                  </a>
                </div>
              </div>
              <a href="/contact" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-[#e4e4e3] hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                contact
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleTogglePopup();
                }}
                className="px-4 py-2 bg-[#b7a78b] hover:bg-[#d0c3a6] transition-colors duration-300 rounded text-[11px] text-[#e4e4e3] uppercase"
              >
                Subscribe
              </a>
              
            </nav>

            {isPopupOpen && (
                <div className="fixed top-0 left-0 w-full bg-[#252525] shadow-lg z-50">
                  <div className="max-w-7xl mx-auto px-4 py-6">
                    <h2 className="text-lg text-white font-semibold mb-4">Subscribe</h2>
                    <form onSubmit={subscribeForm}>
                      <div className="flex space-x-4 mb-4">
                        <div className="flex-1">
                          <label htmlFor="name" className="block text-sm font-medium text-white">Name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                          />
                        </div>
                        <div className="flex-1">
                          <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                          />
                        </div>
                      </div>
                      <div className="flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={handleTogglePopup}
                          className="px-4 py-2 text-white rounded hover:underline"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 bg-[#b7a78b] text-[#e4e4e3] rounded hover:bg-[#d0c3a6]"
                        >
                          Subscribe
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}



            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-[#e4e4e3] hover:text-white focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>


      <div className={`md:hidden overflow-hidden transition-all duration-500 ease-in-out bg-[#252525] ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
          <div className="flex flex-col items-center py-2 shadow-sm space-y-4">
            <a href="/" className="block px-4 py-2 text-[12px] text-[#e4e4e3] hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsOpen(false)}>
              Overview
            </a>
            {/* Portfolio with Dropdown */}
            <div className="w-full">
              <button className="block w-full px-4 py-2 text-[12px] text-[#e4e4e3] hover:bg-gray-100 hover:text-black uppercase tracking-[0.2em] page-scroll text-white" 
              onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}>
                Portfolios
              </button>
              {isPortfolioOpen && (
                <div className="w-full px-4 space-y-2 text-center">
                  {brands.map((brand) => (
                    <a 
                      href={`/portfolios/${brand.name
                        .replace(/\//g, '--') // Replace slashes with double hyphens
                        .replace(/\s+/g, '-') // Replace spaces with single hyphens
                        .replace(/-{2,}/g, '--')
                        .toLowerCase()}`}
                      key={brand._id} 
                      className="block px-4 py-2 text-[14px] text-[#e4e4e3] hover:bg-gray-800 capitalize">
                      {brand.name}
                    </a>
                  ))}
                  <a href="/portfolios/motions"
                    className="block px-4 py-2 text-[14px] text-[#e4e4e3] hover:bg-gray-800 capitalize">motions</a>
                </div>
              )}
            </div>
            {/* Thoughts */}
            <a href="/thoughts" className="block px-4 py-2 text-[12px] text-[#e4e4e3] hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-[#e4e4e3]" onClick={() => setIsOpen(false)}>
              Thoughts
            </a>
            {/*<a href="/prints" className="block px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-[#e4e4e3]" onClick={() => setIsOpen(false)}>
              Prints
            </a>*/}
            {/* About with Dropdown */}
            <div className="w-full">
              <button className="block w-full px-4 py-2 text-[12px] text-[#e4e4e3] hover:bg-gray-100 hover:text-black uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsAboutOpen(!isAboutOpen)}>
                exhibitions
              </button>
              {isAboutOpen && (
                <div className="w-full px-4 space-y-2 text-center">
                  <a href="/exhibitions/in-her-hands" className="block px-4 py-2 text-[14px] text-white hover:bg-gray-800 capitalize">
                    in her hands
                  </a>
                </div>
              )}
            </div>
            <div className="w-full">
              <button className="block w-full px-4 py-2 text-[12px] text-[#e4e4e3] hover:bg-gray-100 hover:text-black uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsAboutOpen(!isAboutOpen)}>
                About
              </button>
              {isAboutOpen && (
                <div className="w-full px-4 space-y-2 text-center">
                  <a href="/about/on-set" className="block px-4 py-2 text-[14px] text-[#e4e4e3] hover:bg-gray-800 capitalize">
                    On Set
                  </a>
                  <a href="/learn-more" className="block px-4 py-2 text-[14px] text-[#e4e4e3] hover:bg-gray-800 capitalize">
                    Bio
                  </a>
                  {/*<a href="/about/faq" className="block px-4 py-2 text-[14px] text-white :bg-gray-800 uppercase">
                    FAQ
                  </a>*/}
                </div>
              )}
            </div>
            {/* Contact */}
            <a href="/contact" className="block px-4 py-2 text-[12px] text-[#e4e4e3] hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsOpen(false)}>
              Contact
            </a>
            <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleTogglePopup();
                }}
                className="px-4 py-2 bg-[#b7a78b] hover:bg-[#d0c3a6] transition-colors duration-300 rounded text-sm text-white"
              >
                Subscribe
              </a>


              {isPopupOpen && (
                <div className="fixed top-0 left-0 w-full bg-[#252525] shadow-lg z-50">
                  <div className="max-w-7xl mx-auto px-4 py-6">
                    <h2 className="text-lg text-white font-semibold mb-4">Subscribe</h2>
                    <form onSubmit={subscribeForm}>
                      <div className="flex flex-col md:flex-row md:space-x-4 mb-4 space-y-4 md:space-y-0">
                        <div className="flex-1">
                          <label htmlFor="name" className="block text-sm font-medium text-white">Name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                          />
                        </div>
                        <div className="flex-1">
                          <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                          />
                        </div>
                      </div>
                      <div className="flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={handleTogglePopup}
                          className="px-4 py-2 text-white rounded"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 bg-[#b7a78b] text-white rounded hover:bg-[#d0c3a6]"
                        >
                          Subscribe
                        </button>
                      </div>
                    </form>
                  </div>
  
                </div>
              )}
          </div>
        </div>




    </nav>
    </>
  );
};

export default Navbar;
